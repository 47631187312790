.App {
  position: relative;
  height: 100vh;
}

main {
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -160px;
  margin-top: -160px;
  box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.08);
  border-radius: 10px;
  padding: 50px;
}

main input {
  border-radius: 5px;
  border: 1px solid #969696;
  width: 100%;
  margin-top: 0px;
  padding: 5px;
  box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.08);
}
main button {
  margin-top: 10px;
  width: 100%;
  background-color: #2D8CFF;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681F2;
}
